import { useState } from 'react';
import { useCookie, useLocation } from 'react-use';
import {
  ModalExitIntentModal,
  type Props as ModalExitIntentModalProps,
} from '../Modal/Modal';
import {
  VisibilityController,
  type Props as VisibilityControllerProps,
} from '../VisibilityController/VisibilityController';

export const COOKIE_STORAGE_NAME = 'exit-modal';
export const COOKIE_ALREADY_SEEN_VALUE = '1';
export const DEFAULT_IDLE_DELAY_MILLISECONDS = 15000;

type ModalExitINtentContainerProps = {
  backgroundImage: ModalExitIntentModalProps['backgroundImage'];
} & Pick<Partial<VisibilityControllerProps>, 'idleDelayMilliseconds'>;

type ModalExitIntentProps = {
  backgroundImage: ModalExitIntentModalProps['backgroundImage'];
} & Pick<Partial<VisibilityControllerProps>, 'idleDelayMilliseconds'>;

export const ModalExitIntentContainer = ({
  backgroundImage,
  idleDelayMilliseconds = DEFAULT_IDLE_DELAY_MILLISECONDS,
}: ModalExitINtentContainerProps) => {
  return (
    <ModalExitIntent
      backgroundImage={backgroundImage}
      idleDelayMilliseconds={idleDelayMilliseconds}
    />
  );
};

export function ModalExitIntent({
  backgroundImage,
  idleDelayMilliseconds = DEFAULT_IDLE_DELAY_MILLISECONDS,
}: ModalExitIntentProps) {
  const location = useLocation();
  const [cookieValue, setCookieValue] = useCookie(COOKIE_STORAGE_NAME);
  // only care about the initial value for showing the modal
  const [initialCookieValue] = useState(cookieValue);
  const [couponRequestedCookie] = useCookie('coupon_requested');
  const [dismissed, setDismissed] = useState(() => {
    return (
      location.search?.includes('promo') ||
      couponRequestedCookie !== null ||
      initialCookieValue === COOKIE_ALREADY_SEEN_VALUE
    );
  });

  if (dismissed === true) {
    return null;
  }

  return (
    <VisibilityController
      onShow={() => {
        setCookieValue(COOKIE_ALREADY_SEEN_VALUE);
      }}
      idleDelayMilliseconds={idleDelayMilliseconds}
    >
      <ModalExitIntentModal
        onClose={() => {
          setDismissed(true);
        }}
        backgroundImage={backgroundImage}
      />
    </VisibilityController>
  );
}
